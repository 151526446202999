import React from 'react';
import { ArticleCard } from '../article-card';
import * as styles from './article-card-list.module.scss';
import { Box } from '@mui/material';
import { FavouriteArticlePage } from './favourite-article-page.type';
import { ContentfulEcomCommon } from '../../contentful';

interface ArticleCardListProps {
  articleList: FavouriteArticlePage[];
  ecomCommon: ContentfulEcomCommon;
}

export const ArticleCardList = ({
  articleList,
  ecomCommon,
}: ArticleCardListProps) => {
  return (
    <>
      <Box component="div" className={styles.articleCardList}>
        {articleList.map((article, index) => (
          <ArticleCard
            ecomCommon={ecomCommon}
            articlePage={article}
            key={`article-${article.id}-${index}`}
          />
        ))}
      </Box>
    </>
  );
};
