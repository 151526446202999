import React from 'react';
import { OfficeList } from '../office-list';
import { RichText } from '../rich-text';
import FavouriteList from '../favourite-list/favourite-list';

/**
 * In all GraphQL Queries the `__typename` property must be defined and paired
 * with the responsible React component in the map called `components`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const components: { [typename: string]: any } = {
  ContentfulOfficeList: OfficeList,
  ContentfulRichText: RichText,
  ContentfulFavorites: FavouriteList,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PageBlocks = ({ data }: any) => {
  const ComponentToRender = components[data?.__typename ?? ''];

  if (ComponentToRender) {
    return <ComponentToRender data={data} />;
  }
  return null;
};
