import { RootState } from '../../store';
import React from 'react';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';
import { useGetArticleList, useGetEcomCommon } from '../../hooks';
import { ArticleCardList } from '../article-card-list';
import { ContentfulFavorites } from '../../contentful';
import { FavouriteArticlePage } from '../article-card-list/favourite-article-page.type';

const mapStateToProps = (state: RootState) => {
  return { favouritesFromStore: state.shop.favourites };
};

interface FavouriteListProps {
  favouritesFromStore: string[];
  data: ContentfulFavorites;
}
const FavouriteList = ({ favouritesFromStore, data }: FavouriteListProps) => {
  const articleList = useGetArticleList();
  const ecomCommon = useGetEcomCommon();

  const favoriteArticleList: FavouriteArticlePage[] = articleList
    .filter((article) => favouritesFromStore.includes(article.articleNumber))
    .map((article) => {
      return { ...article, isFavourite: true };
    });

  return (
    <>
      <Typography variant="h1" component="h2" sx={{ my: 4 }}>
        {data.title}
      </Typography>
      <ArticleCardList
        articleList={favoriteArticleList}
        ecomCommon={ecomCommon}
      ></ArticleCardList>
    </>
  );
};

export default connect(mapStateToProps)(FavouriteList);
