import { Card, CardContent, Typography, Button } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './article-card.module.scss';
import { graphql, Link, navigate } from 'gatsby';
import { ArticleFavouriteToggle } from '../article-favourite-toggle';
import { FavouriteArticlePage } from '../article-card-list/favourite-article-page.type';
import { ContentfulEcomCommon } from '../../contentful';

interface ArticleCardProps {
  articlePage: FavouriteArticlePage;
  ecomCommon: ContentfulEcomCommon;
}
export const ArticleCard = ({ articlePage, ecomCommon }: ArticleCardProps) => {
  return (
    <Card elevation={3} sx={{ display: 'flex', flexDirection: 'column' }}>
      <CardContent className={styles.cardContent}>
        <div className={styles.imageWrapper}>
          {articlePage.image && (
            <GatsbyImage
              image={articlePage.image.gatsbyImage}
              alt={articlePage.title}
              className={styles.image}
              style={{ maxHeight: 100 }}
              imgStyle={{ objectFit: 'contain' }}
            />
          )}
        </div>

        <div className={styles.title}>
          <Typography component="h3" variant="h3">
            <Link to={`/produkt/${articlePage.slug}`}>{articlePage.title}</Link>
          </Typography>
        </div>
        <div className={styles.favorite}>
          <ArticleFavouriteToggle
            favourite={articlePage.isFavourite}
            articleNumber={articlePage.articleNumber}
          />
        </div>
        <div className={styles.articleNumber}>
          {ecomCommon.articleNumber}:<br />
          {articlePage.articleNumber}
        </div>
        <div className={styles.buy}>
          <Button
            sx={{ py: 2 }}
            variant="outlined"
            onClick={() => navigate(`/produkt/${articlePage.slug}`)}
          >
            {ecomCommon.buy}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export const query = graphql`
  fragment ArticleCard on ContentfulPageArticle {
    id
    articleNumber
    title
    slug
    image {
      gatsbyImage(width: 80)
    }
  }
`;
