import { Card, Typography } from '@mui/material';
import React from 'react';
import { ContentfulOffice, ContentfulOfficeList } from '../../contentful';
import { RichText } from '../rich-text';
import * as styles from './office-list.module.scss';

interface OfficeListProps {
  data: ContentfulOfficeList;
}

export const OfficeList = ({ data }: OfficeListProps) => {
  const Office = ({
    address,
    title,
    email,
    labelEmail,
    labelPhone,
    phone,
  }: ContentfulOffice) => {
    return (
      <div className={styles.officeItem}>
        <Typography component="h3" variant="subtitle2">
          {title}
        </Typography>
        <RichText
          data={{ richText: { raw: address?.raw ?? '', references: [] } }}
        />
        {phone && phone.length > 0 && (
          <p>
            {labelPhone} <a href={'tel:' + phone}>{phone}</a>
          </p>
        )}
        {email && email.length > 0 && (
          <p>
            {labelEmail} <a href={'mailto:' + email}>{email}</a>
          </p>
        )}
      </div>
    );
  };

  return (
    <Card sx={{ boxShadow: 5, backgroundColor: '#fff' }}>
      <Typography
        component="h2"
        variant="subtitle1"
        className={styles.mainTitle}
      >
        Våra kontor
      </Typography>
      <div className={styles.officeList}>
        {data.offices.map((office, index) => (
          <Office key={index} {...office} />
        ))}
      </div>
    </Card>
  );
};
