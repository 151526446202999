import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { ContentfulSettings } from '../contentful';
import { PageBlocks } from '../components/page-blocks';
import { Helmet } from 'react-helmet';

interface IndexPageQueryProps {
  contentfulSettings: ContentfulSettings;
}
type IndexPageType = PageProps<IndexPageQueryProps>;

const IndexPage = ({ data }: IndexPageType) => {
  const components = data.contentfulSettings.startPage.components;

  return (
    <>
      <Helmet title={data.contentfulSettings.startPage.title} />
      {components &&
        components.map((component) => (
          <PageBlocks data={component} key={component.id} />
        ))}
    </>
  );
};

export const query = graphql`
  query {
    contentfulSettings {
      startPage {
        ...StartPage
      }
    }
  }
`;

export default IndexPage;
